<template>
	<component
		:is="nodeType"
		tabindex="0"
		v-bind="$attrs"
		:disabled="disabled"
		:href="(!disabled && !isNuxt && href) || undefined"
		:to="(!disabled && to) || href || undefined"
		:data-href="!disabled && (href || to)"
		@click="$emit('click', $event)"
		:class="[
			'button',
			size,
			buttonStyle,
			{ responsive },
			{
				dark,
			},
			{
				block,
			},
			{
				loading,
			},
			{
				disabled,
			},
			{
				'icon-only': iconOnly,
				'icon-left': !!iconLeft,
				'icon-right': !!iconRight,
			},
		]"
	>
		<span
			v-if="(iconLeft || $slots['icon-left']) && !iconOnly"
			class="icon-left"
			:class="{
				left: block,
			}"
		>
			<slot name="icon-left">
				<TnIcon
					:name="iconLeft"
					:size="iconSize"
				/>
			</slot>
		</span>

		<span
			v-if="(text || $slots['default']) && !iconOnly"
			class="text"
			:class="{
				left: iconRight && !iconLeft && block,
				right: iconLeft && !iconRight && block,
			}"
		>
			<slot>{{ text }}</slot>
		</span>

		<TnIcon
			v-else-if="iconOnly"
			:name="iconOnly"
		/>

		<span
			v-if="(iconRight || $slots['icon-right']) && !iconOnly"
			class="icon-right"
			:class="{
				right: block,
			}"
		>
			<slot name="icon-right">
				<TnIcon
					:name="iconRight"
					:size="iconSize"
				/>
			</slot>
		</span>
	</component>
</template>

<script>
import sizes from "./definitions/sizes";

//test

/**
 * TnButton replaces the standard html button with a Telenor-branded button, with three variants (primary, secondary, tertiary),
 * support for icons from our library (or custom icons using <slots>), various sizes and events.
 * @displayName TnButton
 */
export default defineComponent({
	name: "TnButton",

	props: {
		/**
		 * Text/label of the button, will be overwritten by the default slot if passing in text/content
		 */
		text: {
			type: String,
			default: "",
		},
		/**
		 * Name of the icon (from the TnIcon-library) to display on the left-hand side of the button text/content
		 */
		iconLeft: {
			type: String,
			default: "",
		},
		/**
		 * Name of the icon (from the TnIcon-library) to display on the right-hand side of the button text/content
		 */
		iconRight: {
			type: String,
			default: "",
		},
		/**
		 *  Name of the icon (from the TnIcon-library), will override **any** text/content/slot and display a variant with no text just icon
		 */
		iconOnly: {
			type: String,
			default: "",
		},
		/**
		 * Size of the button
		 * @values xs, s, m, l
		 */
		size: {
			type: String,
			default: "m",
			validator: function (value) {
				return sizes.includes(value.toLowerCase()) || "m";
			},
		},
		/**
		 * Gives button **full width** relative to container with _display: block_
		 */
		block: {
			type: Boolean,
			default: false,
		},
		/**
		 * Primary button state should be used as *main* CTA, or when needing a strong visual indicator to help users complete a journey
		 */
		primary: {
			type: Boolean,
			default: false,
		},
		/**
		 * Secondary button state should be used as an _alternative_ to the context of the primary state. For example _'Cancel'_ or _'Reset'_
		 */
		secondary: {
			type: Boolean,
			default: false,
		},
		/**
		 * Tertiary button state should be used for all other/miscellaneous actions that are beyond the likely primary/secondary interaction for the user, such as _'Edit'_
		 */
		tertiary: {
			type: Boolean,
			default: false,
		},
		/**
		 * Disabled the button and removes the ability to click or target the component
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * If set, will dynamically make the component node type either a **router-link** or **nuxt-link** (depending on app context)
		 */
		to: {
			type: String,
		},
		/**
		 * If set, will render the component node as **\<a>**-tag for linking with href
		 */
		href: {
			type: String,
		},
		/**
		 * Gives the component a skeleton-loading animation
		 */
		loading: {
			type: Boolean,
			default: false,
		},
		/**
		 * Dark theming
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * Responsive size m on desktop s on mobile -> band aid to prevent layout shift until new design system is in place
		 */
		responsive: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		/**
		 * Event emitted when the button is clicked
		 */
		"click",
	],

	computed: {
		nodeType() {
			if (this.to || this.href) return resolveComponent("NuxtLink");
			return "button";
		},
		buttonStyle() {
			return this.primary ? "primary" : this.secondary ? "secondary" : this.tertiary ? "tertiary" : "primary";
		},
		iconSize() {
			if (this.responsive) {
				return "m";
			}
			switch (this.size) {
				case "xs":
					return "s";
				case "s":
				case "m":
				case "l":
					return "m";
				case "xl":
					return "l";
				default:
					return "m";
			}
		},
		isNuxt() {
			return !!this.$nuxt;
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/typography/scss/placeholders";
@use "@/assets/scss/variables" as variables;
@use "@/assets/global-style/scss/mixins/loading" as mixin;
@use "@/assets/typography/scss/mixins" as type;
@use "sass:color";

$button-icon-margin: 8px;
$button-height-xs: 32px;
$button-height-s: 40px;
$button-height-m: 48px;
$button-height-l: 56px;
$button-icon-only-size-xs: 24px;
$button-icon-only-size-s: 28px;
$button-icon-only-size-m: 32px;
$button-icon-only-size-l: 40px;

@function hexToRGB($hex) {
	@return color.channel($hex, "red", $space: rgb), color.channel($hex, "green", $space: rgb),
		color.channel($hex, "blue", $space: rgb);
}

.button {
	--button-foreground-color: #{variables.$color-primary-superlight};
	--button-background-color: #{variables.$color-cta-default};
	--button-hover-foreground-color: #{variables.$color-primary-superlight};
	--button-hover-background-color: #{variables.$color-cta-hover};
	--button-active-foreground-color: #{variables.$color-primary-superlight};
	--button-active-background-color: #{variables.$color-cta-active};
	--button-disabled-foreground-color: #{variables.$color-neutrals-500-core};
	--button-disabled-background-color: #{variables.$color-cta-disabled};
	--button-focus-color: #{variables.$color-cta-focus};

	box-sizing: border-box !important;
	font-family: variables.$font-family-telenor-base;
	border: none;
	border-radius: 50px;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--button-foreground-color);
	background-color: var(--button-background-color);

	@include mixin.loading;

	&:active {
		color: var(--button-active-foreground-color);
		background-color: var(--button-active-background-color);
		transition: background-color 40ms ease-out;
	}

	&:focus-visible {
		outline: 2px solid var(--button-focus-color);
		outline-offset: 3px;
	}
	@mixin size-s() {
		padding: 9px 16px;
		max-height: $button-height-s;

		@include type.font-text-bold-s;

		&.icon-left {
			padding-left: 14px;
		}

		&.icon-right {
			padding-right: 14px;
		}

		&.icon-only {
			width: $button-height-s;
			height: $button-height-s;
			padding: 8px;

			> svg {
				width: $button-icon-only-size-s;
				height: $button-icon-only-size-s;
			}
		}
	}
	&.s {
		@include size-s();
	}

	&.xs {
		padding: 5px 16px;
		max-height: $button-height-xs;

		@extend %font-text-bold-xs;

		&.icon-only {
			width: $button-height-xs;
			height: $button-height-xs;

			> svg {
				width: $button-icon-only-size-xs;
				height: $button-icon-only-size-xs;
			}
		}
	}

	@mixin size-m() {
		padding: 11px 24px;
		max-height: $button-height-m;

		@include type.font-text-bold-m;

		&.icon-only {
			width: $button-height-m;
			height: $button-height-m;

			> svg {
				width: $button-icon-only-size-m;
				height: $button-icon-only-size-m;
			}
		}
	}
	&.m {
		@include size-m();
	}

	&.l {
		padding: 14px 32px;
		max-height: $button-height-l;

		@extend %font-text-bold-l;

		&.icon-only {
			width: $button-height-l;
			height: $button-height-l;

			> svg {
				width: $button-icon-only-size-l;
				height: $button-icon-only-size-l;
			}
		}
	}

	&.responsive {
		@include size-m;
		@include breakpoint(mobile) {
			@include size-s;
		}
	}

	&.secondary {
		--button-foreground-color: #{variables.$color-cta-default};
		--button-background-color: rgba(#{hexToRGB(variables.$color-cta-default-background)}, 0.1);
		--button-hover-foreground-color: #{variables.$color-cta-hover};
		--button-hover-background-color: rgba(#{hexToRGB(variables.$color-cta-hover-background)}, 0.2);
		--button-active-foreground-color: #{variables.$color-cta-active};
		--button-active-background-color: var(--button-hover-background-color);

		&:active {
			outline: none;
		}
	}

	&.tertiary {
		--button-foreground-color: #{variables.$color-cta-default};
		--button-background-color: none;
		--button-hover-foreground-color: #{variables.$color-cta-hover};
		--button-hover-background-color: rgba(#{hexToRGB(variables.$color-cta-hover-background)}, 0.1);
		--button-active-foreground-color: #{variables.$color-cta-active};
		--button-active-background-color: none;
	}

	&.disabled {
		color: var(--button-disabled-foreground-color) !important;
		background-color: var(--button-disabled-background-color) !important;
		outline: none;
		cursor: not-allowed;

		&.tertiary {
			--button-disabled-background-color: none;
			--button-foreground-color: #{variables.$color-neutrals-500-core};
			--button-hover-foreground-color: var(--button-foreground-color);
			--button-hover-background-color: none;
		}
	}

	&.block {
		display: flex;
		width: 100%;
		text-align: center;
	}

	.icon-left {
		margin-right: $button-icon-margin;
	}

	.icon-right {
		margin-left: $button-icon-margin;
	}

	.left {
		float: left;
	}

	.right {
		float: right;
	}

	.text {
		vertical-align: middle;
		white-space: nowrap;
	}

	.icon-left,
	.icon-right {
		vertical-align: middle;
		line-height: 0.5;
	}

	&.icon-only {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 0;
	}

	&.dark {
		--button-foreground-color: #{variables.$color-primary-dark};
		--button-background-color: #{variables.$color-cta-dark-default};
		--button-hover-foreground-color: #{variables.$color-primary-dark};
		--button-hover-background-color: #{variables.$color-cta-dark-hover};
		--button-active-foreground-color: #{variables.$color-primary-dark};
		--button-active-background-color: #{variables.$color-cta-dark-active};
		--button-focus-color: #{variables.$color-cta-dark-focus};
		--button-disabled-foreground-color: #{variables.$color-neutrals-400-tint};
		--button-disabled-background-color: #{variables.$color-cta-dark-disabled};

		&.secondary {
			--button-foreground-color: #{variables.$color-cta-dark-default};
			--button-background-color: rgba(#{hexToRGB(variables.$color-cta-dark-default-background)}, 0.2);
			--button-hover-foreground-color: #{variables.$color-cta-dark-hover};
			--button-hover-background-color: rgba(#{hexToRGB(variables.$color-cta-dark-hover-background)}, 0.3);
			--button-active-foreground-color: #{variables.$color-cta-dark-active};
			--button-active-background-color: var(--button-background-color);
		}

		&.tertiary {
			--button-foreground-color: #{variables.$color-cta-dark-default};
			--button-background-color: none;
			--button-hover-foreground-color: #{variables.$color-cta-dark-hover};
			--button-hover-background-color: rgba(#{hexToRGB(variables.$color-cta-dark-default-background)}, 0.2);
			--button-active-foreground-color: #{variables.$color-cta-dark-active};
			--button-active-background-color: none;
			--button-disabled-background-color: none;
		}
	}

	@media (pointer: fine) {
		&:hover {
			color: var(--button-hover-foreground-color);
			background-color: var(--button-hover-background-color);
			transition: background-color 200ms cubic-bezier(0.8, 0, 0.2, 0.1);
		}
	}
}
</style>
